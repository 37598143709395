import { create } from '@storybook/theming';
import { addons } from '@storybook/addons';
import logo from './assets/fresha_logo.svg';

addons.setConfig({
  theme: create({
    base: 'dark',
    brandImage: logo,
  }),
});
